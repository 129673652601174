// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cart from "../../Cart/Cart.res.js";
import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NavSignedOut from "../NavSignOut/NavSignedOut.res.js";
import * as MobileNavigation from "../Mobile/MobileNavigation.res.js";
import * as ResourceCategory from "../../../../models/ResourceCategory.res.js";
import * as React$1 from "@headlessui/react";
import * as IconHamburgerMenu from "../../../icons/IconHamburgerMenu.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DesktopSignedInNav from "./components/signed-in/DesktopSignedInNav.res.js";
import * as DesktopNavigationCss from "./DesktopNavigationCss.res.js";
import LogoWhiteSvg from "Images/logo-white.svg";
import * as DesktopNavigationMenus from "./components/menus/DesktopNavigationMenus.res.js";

var initialState = {
  show: false
};

function reducer(state, action) {
  return {
          show: !state.show
        };
}

function DesktopNavigation$DesktopNavigation(props) {
  var cartQty = props.cartQty;
  var setCartQty = props.setCartQty;
  var toggleCart = props.toggleCart;
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var isCartOpen = props.isCartOpen;
  var resources = props.resources;
  var setUserData = props.setUserData;
  var userData = props.userData;
  var userLoginStatus = props.userLoginStatus;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var headerRef = React.useRef(null);
  React.useEffect((function () {
          setCartQty(cartQty);
          var header = headerRef.current;
          if (!(header == null)) {
            var parent = header.parentElement;
            if (!(parent == null)) {
              parent.className = DesktopNavigationCss.stickyHeader;
            }
            
          }
          
        }), []);
  var toggleMenu = function () {
    dispatch("Toggle");
  };
  var tmp;
  tmp = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(DesktopSignedInNav.make, {
          userData: userData,
          setUserData: setUserData,
          isConcierge: isConcierge,
          isNotOnlyProviderMember: isNotOnlyProviderMember
        }) : JsxRuntime.jsx(NavSignedOut.make, {
          device: "Desktop"
        });
  return JsxRuntime.jsxs("header", {
              children: [
                JsxRuntime.jsxs("nav", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("a", {
                                    children: JsxRuntime.jsx("img", {
                                          alt: "Logo",
                                          src: LogoWhiteSvg
                                        }),
                                    "aria-label": "Datacenters.com",
                                    href: "/"
                                  }),
                              className: DesktopNavigationCss.logoWrapper
                            }),
                        JsxRuntime.jsxs("button", {
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: "Open\n              main\n              menu",
                                      className: "sr-only"
                                    }),
                                JsxRuntime.jsx(IconHamburgerMenu.make, {
                                      size: "MD",
                                      color: "White",
                                      className: DesktopNavigationCss.openMenuIcon
                                    })
                              ],
                              className: DesktopNavigationCss.openMenuButton,
                              id: "open-menu-button",
                              onClick: (function (_event) {
                                  dispatch("Toggle");
                                })
                            }),
                        JsxRuntime.jsxs(React$1.PopoverGroup, {
                              className: DesktopNavigationCss.popoverGroup,
                              children: [
                                JsxRuntime.jsx(DesktopNavigationMenus.Colocation.make, {}),
                                JsxRuntime.jsx(DesktopNavigationMenus.BareMetal.make, {}),
                                JsxRuntime.jsx(DesktopNavigationMenus.Cloud.make, {}),
                                JsxRuntime.jsx(DesktopNavigationMenus.Providers.make, {}),
                                JsxRuntime.jsx(DesktopNavigationMenus.Knowledge.make, {
                                      resources: resources
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: tmp,
                              className: DesktopNavigationCss.signInNavWrapper
                            })
                      ],
                      "aria-label": "Global",
                      className: DesktopNavigationCss.navWrapper
                    }),
                JsxRuntime.jsx(MobileNavigation.make, {
                      userData: userData,
                      setUserData: setUserData,
                      resources: resources,
                      userLoginStatus: userLoginStatus,
                      isCartOpen: isCartOpen,
                      isConcierge: isConcierge,
                      isNotOnlyProviderMember: isNotOnlyProviderMember,
                      toggleCart: toggleCart,
                      setCartQty: setCartQty,
                      cartQty: cartQty,
                      totalCartQuantity: props.totalCartQuantity,
                      toggleAction: toggleMenu,
                      toggleState: match[0].show
                    }),
                JsxRuntime.jsx(Cart.make, {
                      isOpen: isCartOpen,
                      closeModal: toggleCart,
                      setCartQty: setCartQty,
                      userLoginStatus: userLoginStatus
                    })
              ],
              ref: Caml_option.some(headerRef),
              className: DesktopNavigationCss.outerWrapper
            });
}

var DesktopNavigation = {
  Css: undefined,
  initialState: initialState,
  reducer: reducer,
  make: DesktopNavigation$DesktopNavigation
};

function DesktopNavigation$default(props) {
  return JsxRuntime.jsx(DesktopNavigation$DesktopNavigation, {
              userLoginStatus: User.LoginStatus.fromBool(props.isUserLoggedIn),
              userData: User.fromJs(props.userData),
              setUserData: props.setUserData,
              resources: Belt_Array.map(props.resources, ResourceCategory.fromJs),
              isCartOpen: props.isCartOpen,
              isConcierge: props.isConcierge,
              isNotOnlyProviderMember: props.isNotOnlyProviderMember,
              toggleCart: props.toggleCart,
              setCartQty: props.setCartQty,
              cartQty: props.cartQty,
              totalCartQuantity: props.totalCartQuantity
            });
}

var $$default = DesktopNavigation$default;

export {
  DesktopNavigation ,
  $$default as default,
}
/* Cart Not a pure module */
