// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../Link/A.res.js";
import * as Char from "../../../../../../libs/Char.res.js";
import * as Routes_Tsd from "../../../../../../routes/common/Routes_Tsd.res.js";
import * as NavSignedIn from "../../../NavSignedIn/NavSignedIn.res.js";
import * as Routes_City from "../../../../../../routes/common/Routes_City.res.js";
import * as Routes_Lead from "../../../../../../routes/common/Routes_Lead.res.js";
import * as Routes_Page from "../../../../../../routes/common/Routes_Page.res.js";
import * as Routes_User from "../../../../../../routes/common/Routes_User.res.js";
import * as Routes_Order from "../../../../../../routes/common/Routes_Order.res.js";
import * as Routes_State from "../../../../../../routes/common/Routes_State.res.js";
import * as Routes_Agency from "../../../../../../routes/common/Routes_Agency.res.js";
import * as Routes_Country from "../../../../../../routes/common/Routes_Country.res.js";
import * as Routes_Product from "../../../../../../routes/common/Routes_Product.res.js";
import * as Routes_Project from "../../../../../../routes/common/Routes_Project.res.js";
import * as IconChevronDown from "../../../../../icons/IconChevronDown.res.js";
import * as Routes_BlogPost from "../../../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../../../routes/common/Routes_Resource.res.js";
import * as React from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_AgencyAgent from "../../../../../../routes/common/Routes_AgencyAgent.res.js";
import * as Routes_SuspiciousIp from "../../../../../../routes/common/Routes_SuspiciousIp.res.js";
import * as DesktopNavDisclosure from "../disclosure/DesktopNavDisclosure.res.js";
import * as DesktopSignedInNavCss from "./DesktopSignedInNavCss.res.js";
import * as Routes_SuspiciousEmail from "../../../../../../routes/common/Routes_SuspiciousEmail.res.js";

function DesktopSignedInNav(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var userData = props.userData;
  var match = userData.role;
  var tmp;
  tmp = match === "Admin" ? JsxRuntime.jsx("li", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_User.Personal.Dashboard.notifications,
                className: DesktopSignedInNavCss.groupLinkSubItem,
                children: "Notifications"
              })
        }) : null;
  var match$1 = userData.role;
  var tmp$1;
  var exit = 0;
  switch (match$1) {
    case "Admin" :
        tmp$1 = JsxRuntime.jsx(DesktopNavDisclosure.make, {
              label: "Providers",
              children: JsxRuntime.jsxs("ul", {
                    children: [
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsxs(A.make, {
                                  href: Routes_Provider.Dashboard.index,
                                  className: DesktopSignedInNavCss.groupLinkSubItem,
                                  children: [
                                    "Profiles ",
                                    JsxRuntime.jsx(Char.Ampersand.make, {}),
                                    " Listings"
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Project.Dashboard.index,
                                  className: DesktopSignedInNavCss.groupLinkSubItem,
                                  children: "Provider Projects"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Project.Dashboard.conciergeIndex,
                                  className: DesktopSignedInNavCss.groupLinkSubItem,
                                  children: "Concierge Projects"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Product.Dashboard.index,
                                  className: DesktopSignedInNavCss.groupLinkSubItem,
                                  children: "Marketplace Products"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Order.Dashboard.index,
                                  className: DesktopSignedInNavCss.groupLinkSubItem,
                                  children: "Marketplace Orders"
                                })
                          })
                    ],
                    className: DesktopSignedInNavCss.groupLink
                  })
            });
        break;
    case "Provider" :
        tmp$1 = JsxRuntime.jsx(DesktopNavDisclosure.make, {
              label: "Provider Admin",
              children: props.isConcierge ? (
                  isNotOnlyProviderMember ? JsxRuntime.jsxs("ul", {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsxs(A.make, {
                                        href: Routes_Provider.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: [
                                          "Profiles ",
                                          JsxRuntime.jsx(Char.Ampersand.make, {}),
                                          " Listings"
                                        ]
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.conciergeIndex,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Concierge Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Product.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Marketplace Products"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Order.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Marketplace Orders"
                                      })
                                })
                          ],
                          className: DesktopSignedInNavCss.groupLink
                        }) : JsxRuntime.jsxs("ul", {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.conciergeIndex,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Concierge Projects"
                                      })
                                })
                          ],
                          className: DesktopSignedInNavCss.groupLink
                        })
                ) : (
                  isNotOnlyProviderMember ? JsxRuntime.jsxs("ul", {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsxs(A.make, {
                                        href: Routes_Provider.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: [
                                          "Profiles ",
                                          JsxRuntime.jsx(Char.Ampersand.make, {}),
                                          " Listings"
                                        ]
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Product.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Marketplace Products"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Order.Dashboard.index,
                                        className: DesktopSignedInNavCss.groupLinkSubItem,
                                        children: "Marketplace Orders"
                                      })
                                })
                          ],
                          className: DesktopSignedInNavCss.groupLink
                        }) : JsxRuntime.jsx("ul", {
                          children: JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Project.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Provider Projects"
                                    })
                              }),
                          className: DesktopSignedInNavCss.groupLink
                        })
                )
            });
        break;
    case "User" :
    case "Visitor" :
        tmp$1 = null;
        break;
    case "Concierge" :
    case "Agent" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx(DesktopNavDisclosure.make, {
          label: "Projects Admin",
          children: JsxRuntime.jsx("ul", {
                children: JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsx(A.make, {
                            href: Routes_Project.Dashboard.conciergeIndex,
                            className: DesktopSignedInNavCss.groupLinkSubItem,
                            children: "Concierge Projects"
                          })
                    }),
                className: DesktopSignedInNavCss.groupLink
              })
        });
  }
  var match$2 = userData.role;
  var tmp$2;
  tmp$2 = match$2 === "Admin" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DesktopNavDisclosure.make, {
                  label: "General Admin",
                  children: JsxRuntime.jsxs("ul", {
                        children: [
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_BlogPost.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "News"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Resource.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Resources"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_User.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Users"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_User.Dashboard.cloudRack,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "CloudRack™ Users"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Lead.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Leads"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_SuspiciousEmail.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Suspicious Email"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_SuspiciousIp.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Suspicious IPs"
                                    })
                              })
                        ],
                        className: DesktopSignedInNavCss.groupLink
                      })
                }),
            JsxRuntime.jsx(DesktopNavDisclosure.make, {
                  label: "Locations",
                  children: JsxRuntime.jsxs("ul", {
                        children: [
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Location.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Locations"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Country.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Countries"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_State.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "States"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_City.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Cities"
                                    })
                              })
                        ],
                        className: DesktopSignedInNavCss.groupLink
                      })
                }),
            JsxRuntime.jsx(DesktopNavDisclosure.make, {
                  label: "TSDs & Agencies",
                  children: JsxRuntime.jsxs("ul", {
                        children: [
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Tsd.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "TSDs"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Agency.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Agencies"
                                    })
                              }),
                          JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_AgencyAgent.Dashboard.index,
                                      className: DesktopSignedInNavCss.groupLinkSubItem,
                                      children: "Agents"
                                    })
                              })
                        ],
                        className: DesktopSignedInNavCss.groupLink
                      })
                })
          ]
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(React.Popover, {
                      className: DesktopSignedInNavCss.popover,
                      children: [
                        JsxRuntime.jsxs(React.PopoverButton, {
                              className: DesktopSignedInNavCss.popoverButton,
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: "My Dashboard",
                                      "aria-expanded": false
                                    }),
                                JsxRuntime.jsx(IconChevronDown.make, {
                                      size: "XS",
                                      color: "White",
                                      className: DesktopSignedInNavCss.groupOpenIcon
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(React.PopoverPanel, {
                              anchor: "bottom end",
                              transition: true,
                              className: DesktopSignedInNavCss.popoverPanel,
                              children: [
                                JsxRuntime.jsx(DesktopNavDisclosure.make, {
                                      label: "Personal",
                                      children: JsxRuntime.jsxs("ul", {
                                            children: [
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.cloudRack,
                                                          className: DesktopSignedInNavCss.groupLinkSubItem,
                                                          children: "CloudRack™"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_Project.index,
                                                          className: DesktopSignedInNavCss.groupLinkSubItem,
                                                          children: "Projects"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.orders,
                                                          className: DesktopSignedInNavCss.groupLinkSubItem,
                                                          children: "Orders"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.invitations,
                                                          className: DesktopSignedInNavCss.groupLinkSubItem,
                                                          children: "Invitations"
                                                        })
                                                  }),
                                              tmp,
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.edit,
                                                          className: DesktopSignedInNavCss.groupLinkSubItem,
                                                          children: "Edit Profile"
                                                        })
                                                  })
                                            ],
                                            className: DesktopSignedInNavCss.groupLink
                                          })
                                    }),
                                tmp$1,
                                tmp$2,
                                JsxRuntime.jsx(NavSignedIn.make, {
                                      setUserData: props.setUserData
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsx(A.make, {
                      href: Routes_Page.contact,
                      className: DesktopSignedInNavCss.contactUsButton,
                      children: "Contact Us"
                    })
              ],
              className: DesktopSignedInNavCss.wrapper
            });
}

var Css;

var make = DesktopSignedInNav;

export {
  Css ,
  make ,
}
/* A Not a pure module */
