import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../../bundles/Client/common/actions/userActions.jsx';
import { toggleCart, setCartQty } from '../../../../bundles/Client/common/actions/cartActions.jsx';
import TopHeaderNavigation from './TopHeaderNavigation.res.js';

const mapStateToProps = state => ({
  userData: state.user,
  isUserLoggedIn: !!state.user.id,
  isCartOpen: state.cart.isOpen,
  totalCartQuantity: state.cart.totalCartQuantity,
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
  toggleCart: () => dispatch(toggleCart()),
  setCartQty: totalCartQuantity => dispatch(setCartQty(totalCartQuantity)),
});

export default ({ context, ...props }) => {
  let TopHeaderContainer = {};

  TopHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(TopHeaderNavigation);

  return (
    <ReduxProvider {...props}>
      <TopHeaderContainer {...props} {...context} />
    </ReduxProvider>
  );
};
