// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function edit(id) {
  return "/dashboard/agency_agents/" + ID.toString(id) + "/edit";
}

function show(id) {
  return "/dashboard/agency_agents/" + ID.toString(id);
}

var Dashboard = {
  index: "/dashboard/agency_agents",
  edit: edit,
  $$new: "/dashboard/agency_agents/new",
  show: show
};

export {
  Dashboard ,
}
/* ID Not a pure module */
